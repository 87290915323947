<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9129 18.7912L16.088 7.12073C15.2286 5.39901 12.7719 5.39898 11.9125 7.12071L6.0874 18.7912C5.31307 20.3425 6.44127 22.1666 8.17511 22.1666H19.8252C21.559 22.1666 22.6872 20.3425 21.9129 18.7912ZM18.1757 6.0787C16.457 2.63524 11.5435 2.63522 9.8248 6.07866L3.99968 17.7491C2.45103 20.8518 4.70741 24.4999 8.17511 24.4999H19.8252C23.2929 24.4999 25.5493 20.8518 24.0007 17.7492L18.1757 6.0787Z" />
    <path d="M15.1668 9.33268C15.1668 8.68835 14.6445 8.16602 14.0002 8.16602C13.3558 8.16602 12.8335 8.68835 12.8335 9.33268V13.9993C12.8335 14.6437 13.3558 15.166 14.0002 15.166C14.6445 15.166 15.1668 14.6437 15.1668 13.9993V9.33268Z" />
    <path d="M14.0002 17.4993C13.3558 17.4993 12.8335 18.0217 12.8335 18.666C12.8335 19.3103 13.3558 19.8327 14.0002 19.8327C14.6445 19.8327 15.1668 19.3103 15.1668 18.666C15.1668 18.0217 14.6445 17.4993 14.0002 17.4993Z" />
  </svg>
</template>

<script>
export default {
  name: 'Warning',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>

<template>
  <div class="py-3.5 px-2.5 mr-2.5 relative group cont border-b border-primary-200">
    <div class="flex items-center justify-between">
      <!-- 1. left -->
      <div class="flex items-center w-full">
        <div>
          <img @click="goToProject" :src="projectMeta && projectMeta.icon ? projectMeta.icon : ''" class="w-6 h-6 mr-3 cursor-pointer" />
        </div>

        <div class="w-full-custom relative">
          <div
            class="text-sm cursor-pointer group-hover:text-red title"
            :class="{
              'titleTruncated': action==='deleteProject'
            }"
            @click="goToProject"
          >
            {{project.title || 'New project'}}
          </div>

          <div v-if="project.updated" class="mt-0.5 text-xs text-primary-500 group-hover:text-primary-700 title">
            <!-- updated time -->
            <div>{{dayjs(project.updated).fromNow()}}</div>
          </div>
        </div>
      </div>

      <!-- 2. right -->
      <div v-if="action==='deleteProject'" class="flex items-center rightPropmpt">
        <span class="text-sm">Are you sure you want to delete this project?</span>
        <Button
          class="ml-2.5"
          size="small"
          variant="secondary"
          @click="action=''"
        >
          No
        </Button>
        <Button
          class="ml-2.5"
          size="small"
          @click="deleteProject"
        >
          Yes
        </Button>
      </div>

      <div v-else class="flex items-center rightIcons opacity-20 group-hover:opacity-100">
        <div
          class="ml-4 cursor-pointer text-primary-500 deleteIcon group-hover:text-primary"
          @click="action='deleteProject'"
          v-tooltip.top="'Delete'"
        >
          <DeleteIcon/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mapGetters } from 'vuex'
import { updateProject } from '@/services/api/project'
import CONSTANTS from '@/constants/CONSTANTS'

import Button from '@/components/base/buttons/Button.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'

dayjs.extend(relativeTime)

export default {
  name: 'Project',
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      dayjs,
      action: ''
    }
  },
  components: {
    Button,
    DeleteIcon
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    projectMeta: function() {
      return CONSTANTS.PROJECT_TYPES.find(el => el.name === this.project.projectType)
    }
  },
  methods: {
    deleteProject: function() {
      updateProject({
        userId: this.user.uid,
        projectId: this.project.uid,
        update: { deleted: true }
      })
    },
    goToProject: function() {
      this.$emit('goToProject', {
        projectId: this.project.uid,
        path: this.project.path
      })
    }
  }
}
</script>

<style scoped lang="scss">
.rightPropmpt {
  position: absolute;
  z-index: 2;
  right: 24px;
}

.rightIcons {
  position: absolute;
  z-index: 2;
  right: 24px;
}

.deleteIcon:hover {
  color: #E2462C !important;
}

.cont {
  &:hover {
    .title {
      font-weight: 600;
    }
  }
}

.title {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 240px);
}

.title.titleTruncated {
  max-width: calc(100% - 420px);
}

.w-full-custom {
  width: calc(100% - 40px)
}
</style>

<template>
  <div class="relative w-full h-screen overflow-y-auto bg-white rounded-lg">
    <div class="flex mb-2 items-center justify-between">
      <div class="text-3xl font-semibold font-h text-primary flex items-center">
        <img
          src="../../../../assets/icons/Pencil.svg"
          alt="Editor"
          class="mr-2 w-9 h-9"
        />
        Editor Studio
      </div>

      <Button
        @click="openUpload"
        classname="pr-2.75 uploadButton"
      >
        <span>Upload</span>
        <UploadIcon class="w-4 h-4 ml-2 uploadButtonArrow" />
      </Button>
    </div>

    <div
      v-if="!onboardingState && selectedOptionDetails && selectedOptionDetails.message"
      class="mb-4 text-xs text-primary px-3 py-2.75 rounded-lg bg-primary-100 inline-block"
    >
      <span v-show="!onboardingProject" >💡</span>
      <span v-show="!onboardingProject">{{selectedOptionDetails.message}}</span>
    </div>

    <div
      v-else-if="onboardingState && (onboardingState !== 'empty')"
      class="mb-4 text-xs text-primary-100 px-3 py-2.75 rounded-lg bg-primary inline-block"
    >
      <span>💡</span>
      <span>{{onboardingMessages[onboardingState]}}</span>
    </div>

    <div class="flex items-center justify-between w-full border-b border-primary-300">
      <div class="flex items-center">
        <div
          tabindex="0"
          v-for="(actionList, key) in actionLists"
          :key="key"
          class="transition ease-in-out duration-300 flex justify-center text-sm border-b-4 border-transparent cursor-pointer h-10 px-9"
          :class="[selectedOption === actionList.type ? 'items-center text-primary border-red' : 'items-end text-primary-600']"
          @click="changeView(actionList.type)"
        >
          {{ actionList.label }}
        </div>
      </div>
    </div>

    <div class="driveTabContent">
      <SpextDocs v-if="selectedOption === 'spextdocs'" :showUploadModal="showUploadModal"/>
      <Sound v-if="selectedOption === 'sound'" @uploadSound="uploadSound" />
      <Projects v-if="selectedOption === 'projects'" v-on="$listeners" :showUploadModal="showUploadModal" />
      <Latest v-if="selectedOption === 'latest'" v-on="$listeners" :highlightFirst="onboardingProject" />
    </div>
  </div>
</template>

<script>
import store from '@/services/store'
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/base/buttons/Button.vue'
import UploadIcon from '@/components/base/icons/Upload.vue'
import Sound from './sound/sound.vue'
import SpextDocs from './spextDocs/spextDocs.vue'
import Projects from './Projects/index.vue'
import Latest from './Latest.vue'

export default {
  name: 'Main',
  components: {
    Sound,
    SpextDocs,
    Button,
    UploadIcon,
    Projects,
    Latest
  },
  props: {
    showUploadModal: {
      type: Function,
      default: () => null
    },
    onboardingState: {
      type: String,
      default: ''
    },
    onboardingProject: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedOption: 'latest',
      isSearchFocussed: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    uploadedMedia () {
      return store.state.app.uploadedMedia
    },
    files () {
      let list = {}
      for (let key in this.uploadedMedia) {
        if (!this.uploadedMedia[key].deleted) {
          list[key] = this.uploadedMedia[key]
        }
      }
      return list
    },
    actionLists () {
      return [
        {
          label: 'Recent',
          type: 'latest',
          path: '/addfile/latest',
          message: ' Click on the raw recordings (Docs) below to edit them. Then, import them to your projects.'
        },
        {
          label: 'Projects',
          type: 'projects',
          path: '/addfile/projects',
          message: ' Click on the raw recordings (Docs) below to edit them. Then, import them to your projects.'
        },
        {
          label: 'Spext Docs',
          type: 'spextdocs',
          path: '/addfile/spextdocs',
          message: ' Click on the raw recordings (Docs) below to edit them. Then, import them to your projects.'
        },
        {
          label: 'Music Files',
          type: 'sound',
          path: '/addfile/sounds',
          message: Object.keys(this.files).length ? 'You can use files from your music library in your Spext edits. Click below to upload new music.' : 'Upload your music files here. You can use them across projects :)'
        }
      ]
    },
    onboardingMessages () {
      return {
        firstUpload: 'Welcome to Spext! We are processing your first upload. You can access all your uploads below ↓',
        trySample: "You can try the sample file anytime by clicking the '?' icon on the left"
      }
    },
    selectedOptionDetails () {
      return this.actionLists.find(actionList => actionList.type === this.selectedOption)
    }
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal'
    }),
    openUpload: function() {
      this.showUploadModal()
    },
    changeView: function(option) {
      window.analytics.track(`drive_tab_${option}`)
      this.selectedOption = option
    },
    uploadSound: function() {
      this.openModal({
        name: 'AddFile',
        props: { soundMode: true }
      })
    },
    goToDocs: function() {
      this.selectedOption = 'spextdocs'
    }
  }
}
</script>
<style lang="scss" scoped>
.root {
  min-height: calc(100% - 92px);
  border: 1px solid rgba(141, 158, 191, 0.2);
}

.uploadButton {
  position: relative;

  .uploadButtonArrow {
    position: relative;
    top: 0px;
    transition: all 0.2s ease;
  }

  &:hover .uploadButtonArrow {
    top: -2px;
  }
}

.driveTabContent {
  min-height: calc(100vh - 174px);
  max-height: calc(100vh - 174px);
  overflow-y: auto;
}

.z-52 {
  z-index: 52;
}
</style>

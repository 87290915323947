<template lang="html">
  <div class="flex flex-1 h-screen overflow-hidden bg-primary-100">
    <Header />

    <div
      v-if="!$parent.loading && computedList.length===0 && Object.keys(uploadingList).length==0"
      class="relative grid w-full grid-cols-10 gap-4 pl-3 ml-16"
    >
      <section class="relative z-10 h-screen col-span-10 bg-white shadow-lg">
        <div
          class="flex items-center justify-center w-full h-full"
        >
          <VerifyEmail
            @verifyEmail="$parent.verifyEmail"
            :emailVerified="$parent.emailVerified"
            :countdown="$parent.countdown"
            :retryCount="$parent.retryCount"
            :billing="billing"
          />

          <FirstUpload
            @showUploadModal="$parent.showUploadModal"
            :emailVerified="$parent.emailVerified"
            :billing="billing"
          />
        </div>
      </section>
    </div>

    <div v-else class="relative grid w-full grid-cols-10 gap-4 pl-3 ml-16">
      <section class="relative h-screen col-span-7 pt-8 px-8 bg-white shadow-lg"
        :class="{
          'z-10': !onboardingProject
        }"
      >
        <Main
          v-show="!$parent.loading && (computedList.length>0 || Object.keys(uploadingList).length>0)"
          :showUploadModal="$parent.showUploadModal"
          :onboardingState="onboardingState"
          :onboardingProject="onboardingProject"
          @goToProject="goToProject"
        />

        <div class="vtoast">
          <VueToastr ref="toastr" />
        </div>

        <!-- <div
          class="transition ease-in-out duration-300 text-white bg-primary px-2 py-1 rounded-lg absolute left-1/2 transform -translate-x-1/2"
          :class="showUploading ? 'opacity-100 bottom-16' : 'opacity-0 -bottom-2'"
        >
          <span>💡</span>
          <span class="leading-6 text-sm">Till your file uploads, try editing a
            <span
              class="underline cursor-pointer"
              @click="openDemoURL"
            >
              sample file
            </span>
          </span>
        </div> -->
      </section>

      <section
        class="relative flex flex-col h-screen col-span-3 overflow-hidden px-1 bg-white border-l border-primary-300"
      >
      <div class="flex flex-col w-full px-5 pt-7 pb-2 mt-1 bg-white rounded-lg"
        :class="{
          'project-ob': onboardingProject
          }"
      >
        <div class="mb-6 text-2xl font-semibold font-h">
          Integrations
        </div>
        <div
          class="calendar-button transition ease-in-out duration-300 relative flex items-center p-4 mb-4 border rounded-lg cursor-pointer border-primary-200 bg-primary-100 group projectType hover:border-blue hover:bg-blue-50"
          @click="handleCalendarRedirect"
          :class="{
              'disabled': redirectLoading
            }"
        >
          <img src="../../assets/icons/Calendar.svg" class="mr-2 h-6 w-6" />
          <div class="h-8 flex flex-col justify-center">
            <div class="text-primary text-xs group-hover:font-medium group-hover:mb-1 capitalize">
              <span v-if="!redirectLoading">{{isCalendarUsed ? '' : 'Connect'}} your calendar </span>
              <span v-else>Redirecting...</span>
            </div>
            <div v-if="!redirectLoading" class="text-primary text-2xs hidden group-hover:block font-light">
              Record & summarize all meetings automatically
            </div>
          </div>
          <div class="flex-grow" />
          <ChevronRight :class="'projectRightArrow'"/>
        </div>
        <div class="mb-6 text-2xl font-semibold font-h">
          Start a new project
        </div>
        <div
          v-for="el in projectTypes.filter(t => !t.comingSoon)"
          :key="el.name"
          class="transition ease-in-out duration-300 relative flex items-center p-4 mb-2 border rounded-lg cursor-pointer border-primary-200 bg-primary-100 group projectType"
          :class="el.containerHoverClass"
          @click="createAndGoToProject(el)"
        >
          <img :src="el.icon" class="mr-2 h-6 w-6" />
          <div class="h-8 flex flex-col justify-center">
            <div class="text-primary text-xs group-hover:font-medium group-hover:mb-1">
              {{el.title || el.name}}
            </div>
            <div class="text-primary text-2xs hidden group-hover:block font-light">
              {{el.description}}
            </div>
          </div>
          <div class="flex-grow" />
          <ChevronRight :class="[el.hoverClass, 'projectRightArrow']"/>
        </div>
      </div>
      <div class="flex flex-col w-full px-5">
        <div class="mt-5 mb-2 font-semibold font-h opacity-30">Coming soon!</div>
        <div
          v-for="el in projectTypes.filter(t => t.comingSoon)"
          :key="el.name"
          class="flex items-center p-4 mb-2 border rounded-lg border-primary-300 opacity-30"
        >
          <img :src="el.icon" class="mr-2 h-6 w-6" />
          <div class="h-8 flex flex-col justify-center">
            <div class="text-primary text-xs mb-1">
              {{el.name}}
            </div>
            <div class="text-primary text-2xs font-light">
              {{el.description}}
            </div>
          </div>
        </div>
        </div>
      </section>

      <ModalChrome :isMobile="$parent.isMobile" />
    </div>
    <div v-if="onboardingProject" @click="() => {onboardingProject = false}" class="h-screen w-screen z-50 fixed left-0 top-0 onboarding-custom-bg">
      <div class="absolute flex justify-center items-start">
        <div
          class="mb-4 text-xs text-primary-100 px-3 py-2.75 rounded-lg bg-primary inline-block text-center w-max"
        >
          <span>Click on the file ⬆️ to edit it, or use one of our tools️</span>
        </div>
        <img src="../../assets/images/onboarding_project_arrow.svg" />
      </div>
    </div>
  </div>
</template>
<script>
import uuidv1 from 'uuid/v1'
import { mapActions, mapGetters } from 'vuex'
import VueToastr from 'vue-toastr'
import store from '@/services/store'
import { createProject } from '@/services/api/project'
import CONSTANTS from '@/constants/CONSTANTS'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'
import { updatePodcast } from '@/services/api/podcast'
import Main from './core/main/main.vue'
import Header from './core/Header.vue'
import { setOnboardingState } from '@/services/api/account'
import axios from 'axios'

require('@/assets/css/jquery-confirm.min.css')
require('@/assets/js/jquery-confirm.min.js')
require('@fortawesome/fontawesome-pro/css/all.css')

export default {
  name: 'drive',
  components: {
    ChevronRight,
    VueToastr,
    Header,
    Main,
    ModalChrome: () =>
      import(/* webpackChunkName: "ModalChrome" */ '@/components/ModalChrome'),
    VerifyEmail: () =>
      import(/* webpackChunkName: "VerifyEmail" */ './core/VerifyEmail.vue'),
    FirstUpload: () =>
      import(/* webpackChunkName: "FirstUpload" */ './core/FirstUpload.vue')
  },
  data: function () {
    return {
      projectTypes: CONSTANTS.PROJECT_TYPES,
      onboardingState: '',
      onboardingProject: false,
      isCalendarUsed: false,
      redirectLoading: false
    }
  },
  mounted() {
    window._spext_transcriptMode = false
    window.Intercom('update', {
      hide_default_launcher: false
    })
    window.analytics.track('drive_open')
    var vm = this
    this.$parent.startQueueProgress()
    // not a compatible browser
    if (!vm.$parent.isCompatibleBrowser || vm.$parent.isMobile) {
      vm.showChromeModal()
    }
    this.getCalendarStatus()
  },
  beforeCreate() {
    var vm = this
    // check local storage before loading this component
    if (vm.$parent.user.emailVerified) {
      if (localStorage['invite']) {
        this.$router.replace({
          name: 'Invite',
          params: { adminid: localStorage['invite'] }
        })
      } else if (localStorage['teamInvite'] && localStorage['invitedEmail']) {
        this.$router.replace({
          name: 'TeamInvite',
          query: {
            adminid: localStorage['teamInvite'],
            invitedEmail: localStorage['invitedEmail']
          }
        })
      }
    }
  },
  beforeDestroy() {
    this.$parent.stopQueueProgress()
  },
  computed: {
    ...mapGetters({
      user: 'app/user',
      userOnboardingState: 'app/onboardingState',
      openModalName: 'dialogs/modalName',
      computedList: 'app/computedList'
    }),
    newFileID() {
      return store.state.app.newFileID
    },
    billing() {
      return store.state.app.userInfo.billing
    },
    uploadingList() {
      return store.state.app.uploadingList
    },
    inProgress() {
      return store.state.app.inProgress
    },
    showUploading() {
      return (this.computedList.length === 1 && this.computedList[0].doc_progress <= 1 && this.userOnboardingState === '2') || (this.computedList.length === 0 && Object.keys(this.uploadingList).length && this.userOnboardingState === '2')
    },
    showEditorModal() {
      if (this.computedList.length === 1) {
        const firstFile = this.computedList[0]
        if (firstFile && firstFile.doc_progress < 100 && firstFile.doc_progress > 1 && this.userOnboardingState === '2') return true
      }
      return false
    },
    showProjectOnboarding() {
      if (this.computedList.length === 1) {
        const firstFile = this.computedList[0]
        return firstFile.doc_progress === 100 && this.openModalName !== 'EditorModal' && this.userOnboardingState === '3'
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal'
    }),
    async getCalendarStatus() {
      const res = await axios.get(`${CONSTANTS.CAL_INFO_URL}/${this.user.uid}/accounts`)
      console.log(res)
      const calList = res.data.data.accounts
      this.isCalendarUsed = !!calList.length
    },
    async openDemoURL() {
      const sampleMediaId = 'sample'
      await updatePodcast(this.user.uid, sampleMediaId, {
        deleted: false
      }).then(() =>
        window.open(
          `${window.location.origin}/audio/${this.user.uid}/${sampleMediaId}`,
          '_self'
        )
      )
    },
    showToast: function (msg = '', type = 'info') {
      this.$refs.toastr.Add({
        msg,
        type,
        title: type === 'info' ? '💡' : '',
        position: 'toast-bottom-center',
        progressbar: false,
        preventDuplicates: true,
        timeout: 4000
      })
    },
    createAndGoToProject: async function ({
      name,
      type,
      path = 'projects',
      extraSettings
    }) {
      if (type === 'blank') {
        let uuid = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
        localStorage[uuid] = true
        window.open(`audio/${this.user.uid}/${uuid}`, '_self')
        return
      }

      const owner = this.user.uid
      const newProjectId = await createProject(owner, {
        projectType: name,
        brandkitType: type,
        path,
        isShownOnDrive: true,
        ...extraSettings
      })
      this.$router.push({ path: `/${owner}/${path}/${newProjectId}` })
      window.analytics.track('drive_workflowClicked', {
        type: name,
        projectId: newProjectId
      })
    },
    showAdminModal() {
      var vm = this
      vm.$refs.modalAdmin.currentState = 0
      $('.ui.admin.modal')
        .modal({
          detachable: false
        })
        .modal('show')
    },
    closeAdminModal() {
      $('.ui.admin.modal').modal('hide')
    },
    profileSelected(choice) {
      if (choice.title === 'Profile') {
        this.openModal({ name: 'AccountInfo' })
      } else if (choice.title === 'Admin Control') {
        this.openModal({ name: 'AdminControl' })
      } else if (choice.title === 'Logout') {
        this.logOut()
      }
    },
    pauseAll() {
      var vm = this
      for (var i = 0; i < vm.$refs.drivePlayer.length; i++) {
        vm.$refs.drivePlayer[i].player.pause()
      }
    },
    deletePodcast(key) {
      this.$parent.deletePodcast(key)
    },
    showBillingModal(tab) {
      this.openModal({ name: 'AccountInfo', props: { tab } })
    },
    closeUploadModal() {
      this.$parent.closeUploadModal()
    },
    showChromeModal() {
      $('.ui.chrome.modal')
        .modal({
          closable: false
        })
        .modal('show')
    },
    closeChromeModal() {
      $('.ui.chrome.modal').modal('hide')
    },
    strip(str) {
      return str.replace(/^\s+|\s+$/g, '').toLowerCase()
    },
    podcastClicked(podcastKey, audio, audioMp3, title) {
      this.$emit('podcastClicked', podcastKey, audio, audioMp3, title)
    },
    // before logout as user about upload progress etc.
    logOut() {
      var vm = this
      if (Object.keys(vm.uploadingList).length > 0) {
        $.confirm({
          title: 'Cancel Upload?',
          content:
            'Your upload is not complete. Would you like to cancel the upload?',
          useBootstrap: false,
          theme: 'modern',
          escapeKey: 'cancel',
          buttons: {
            confirm: {
              text: 'CONTINUE UPLOAD',
              btnClass: 'btn-blue',
              action: function () {
                console.log('do nothing')
              }
            },
            cancel: {
              text: 'CANCEL UPLOAD',
              btnClass: 'btn-white',
              action: function () {
                vm.finalLogout()
              }
            }
          }
        })
      } else {
        vm.finalLogout()
      }
    },
    finalLogout() {
      store.dispatch('app/logout')
    },
    goToProject: function ({ projectId, path = 'projects' }) {
      this.$router.push(`/${this.user.uid}/${path}/${projectId}`)
    },
    handleCalendarRedirect() {
      if (this.redirectLoading) return
      window.open(`${CONSTANTS.CAL_UI_URL}${this.isCalendarUsed ? '' : '/setup'}`, '_blank')
    }
  },
  watch: {
    showUploading: {
      handler(val) {
        const vm = this
        if (val) {
          vm.onboardingState = 'firstUpload'
        }
      },
      immediate: true
    },
    showEditorModal: {
      handler(val) {
        const vm = this
        if (val) {
          vm.onboardingState = ''
          setOnboardingState(vm.user.uid, '3')
          vm.openModal({ name: 'EditorModal' })
        }
      },
      immediate: true
    },
    showProjectOnboarding: {
      handler(val) {
        const vm = this
        if (val) {
          setOnboardingState(vm.user.uid, '4')
          vm.onboardingProject = val
          setTimeout(() => {
            vm.onboardingProject = false
          }, 6000)
        }
      },
      immediate: true
    },
    openModalName(val, oldVal) {
      if (oldVal === 'EditorModal') {
        const vm = this
        if (vm.userOnboardingState === '3') {
          vm.onboardingState = 'trySample'
          setTimeout(() => {
            vm.onboardingState = ''
          }, 6000)
        }
      }
    }
  }
}
</script>
<style lang="less">
.vtoast {
  .toast-container {
    position: absolute;
    display: flex;
    justify-content: center;
    font-size: 14px;

    div {
      width: unset !important;
      max-width: 300px;
    }

    &:hover > div {
      box-shadow: 0 0 12px #999;
    }

    .toast {
      padding: 5px 12px 5px 8px !important;
      background-image: none !important;

      &:not(:last-child) {
        display: none !important;
      }
    }
  }

  .toast-bottom-center {
    bottom: 48px !important;
  }

  .toast-message {
    font-size: 14px;
  }
}

.projectType {
  .projectRightArrow {
    position: relative;
    right: 0px;
    transition: all 0.3s ease;
  }

  &:hover .projectRightArrow {
    right: -4px;
  }
}

.project-ob {
  z-index: 51;
}

.onboarding-custom-bg {
  background-color: rgba(0,2,40, 0.5);

  >div {
    top: 260px;
    left: 50%;
    transform: translateX(-55%);

    img {
      transform: translateY(15px);
    }
  }
}

.calendar-button.disabled {
  opacity: 0.5;
}
</style>

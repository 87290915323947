<template>
  <div>
    <div
      class="
        cursor-pointer
        flex
        py-3.5
        px-2.5
        mr-2.5
        group
        items-center
        justify-between
        border-b border-primary-300
      "
      v-for="(fileList, i) in uploadingList"
      :key="i + 'u'"
    >
      <div class="flex items-center">
        <BrandIcon
          class="mr-3 h-7 text-primary-500 w-7 group-hover:text-primary"
        />

        <div>
          <div class="text-primary text-sm">
            {{ fileList.title }}
          </div>

          <div class="mt-0.5 text-xs text-primary-500">
            Uploading
            {{
              (
                (fileList.task.snapshot.bytesTransferred /
                  fileList.task.snapshot.totalBytes) *
                100
              ).toFixed(2)
            }}%
          </div>
        </div>
      </div>

      <!-- Actions on  progress of upload -->
      <div class="flex items-center">
        <div
          v-tooltip.top="'Pause Uploading'"
          v-if="fileList.task.state_ === 'running'"
          class="text-primary-500 hover:text-red"
          @click="fileList.task.pause()"
        >
          <Pause />
        </div>
        <div
          v-tooltip.top="'Resume Uploading'"
          v-if="fileList.task.state_ === 'paused'"
          class="text-primary-500 hover:text-red"
          @click="fileList.task.resume()"
        >
          <Play />
        </div>
        <div
          v-tooltip.top="'Delete'"
          @click="cancelUpload(i)"
          class="ml-4 cursor-pointer text-primary-500 hover:text-red"
        >
          <DeleteIcon />
        </div>
      </div>
    </div>

    <DrivePlayerCard
      v-for="(item, index) in fileLists"
      :key="item.key"
      :id="item.key"
      @openTranscript="openTranscript"
      @deletePodcast="deletePodcast"
      :podcast="item"
      :podcastKey="item.key"
      :podIndex="index"
      :class="{
        'no-last-border': index === fileLists.length - 1,
      }"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/services/store'
import { deletePodcast } from '@/services/api/podcast'
import Button from '@/components/base/buttons/Button.vue'
import Play from '@/components/base/icons/Play.vue'
import Pause from '@/components/base/icons/Pause.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
import BrandIcon from '@/components/base/icons/Brand.vue'

import CONSTANTS from '../../../../../constants/CONSTANTS'
import DrivePlayerCard from '../../../DrivePlayerCard.vue'

export default {
  name: 'SpextDocs',
  data() {
    return {
      showUploadIcon: -1,
      showIcon: -1,
      showExport: -1,
      picked: 'correct',
      checked: false,
      showKey: -1,
      fileType: '',
      selectedFile: ''
    }
  },
  components: {
    Play,
    Pause,
    DeleteIcon,
    BrandIcon,
    DrivePlayerCard
  },
  computed: {
    showCreate() {
      if (
        this.userInfo &&
        this.userInfo.billing &&
        Object.keys(this.userInfo.billing).length > 2 &&
        ((this.userInfo.billing.subscribed &&
          CONSTANTS.VOICE_PLANS.indexOf(this.userInfo.billing.subscribed) >=
            0) ||
          this.userInfo.billing.trial)
      ) {
        return true
      }
      return false
    },
    userInfo() {
      return store.state.app.userInfo
    },
    fileLists() {
      return store.state.app.computedList
    },
    user() {
      return store.state.app.user
    },
    uploadingList() {
      return store.state.app.uploadingList
    },
    podcastList() {
      return store.state.app.podcastList
    },
    needsSubscription() {
      if (
        store.state.app.userInfo.billing &&
        store.state.app.userInfo.billing.subscribed === 'free2020'
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal'
    }),
    setPicked: function (picked) {
      this.picked = picked
    },
    openIntercom() {
      window.Intercom('showNewMessage', 'Error in Transcription')
    },
    cancelUpload(key) {
      var vm = this
      var r = confirm('Are you sure you want to cancel ?')
      if (r) {
        vm.uploadingList[key].task.cancel()
        store.commit('app/removeFromUploadingList', key)
      } else {
        console.log('You pressed Cancel!')
      }
    },
    deletePodcast(key) {
      deletePodcast(this.user.uid, key)
    },
    openTranscript(key, index) {
      this.selectedFile = key
      this.routeToEditor(this.selectedFile)
    },
    routeToEditor(podId) {
      let owner = this.podcastList[podId].owner || this.user.uid
      this.$router.push({
        name: 'HomeProse',
        params: { podId: podId, userId: owner }
      })
    }
  }
}
</script>

<style lang="scss">
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.01841 11.6953L4.14294 10.8198L7.96116 7.00163L4.14294 3.18341L5.01841 2.30794L9.71209 7.00163L5.01841 11.6953Z" />
  </svg>
</template>

<script>
export default {
  name: 'Add',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>

<template>
  <div>
    <div v-if="list.length === 0 && Object.keys(uploadingList).length === 0" class="flex flex-col items-center pt-s208">
      <BrandIcon class="text-red h-11 w-11" />
      <div class="text-sm leading-6 text-primary text-center" style="max-width: 228px">
        Welcome to Spext 🙂
        <br />
        Open a <span class="cursor-pointer text-blue" @click="$parent.goToDocs()">Spext Doc</span> or create a project on the right.
      </div>
    </div>

    <div
      class="cursor-pointer flex py-3.5 px-2.5 mr-2.5 group items-center justify-between border-b border-primary-300"
      v-for="(fileList, i) in uploadingList"
      :key="i+'u'"
    >
      <div class="flex items-center">
        <BrandIcon class="h-7 mr-3 text-primary-500 w-7 group-hover:text-primary"/>

        <div>
          <div
            class="text-primary text-sm"
          >
            {{fileList.title}}
          </div>

          <div class="mt-0.5 text-xs text-primary-500">
            Uploading {{((fileList.task.snapshot.bytesTransferred/fileList.task.snapshot.totalBytes)*100).toFixed(2)}}%
          </div>
        </div>
      </div>

      <!-- Actions on  progress of upload -->
      <div class="flex items-center">
        <div
          v-tooltip.top="'Pause Uploading'"
          v-if="fileList.task.state_==='running'"
          class="text-primary-500 hover:text-red"
          @click="fileList.task.pause()"
        >
          <Pause/>
        </div>
        <div
          v-tooltip.top="'Resume Uploading'"
          v-if="fileList.task.state_==='paused'"
          class="text-primary-500 hover:text-red"
          @click="fileList.task.resume()"
        >
          <Play/>
        </div>
        <div
          v-tooltip.top="'Delete'"
          @click="cancelUpload(i)"
          class="cursor-pointer ml-4 text-primary-500 hover:text-red"
        >
          <DeleteIcon/>
        </div>
      </div>
    </div>

    <div
      v-for="(item, i) in list"
      :key="item.uid"
      :class="{'no-last-border': i === list.length-1}"
    >
      <ProjectCard
        v-if="item.type==='project'"
        :project="item"
        v-on="$listeners"
      />
      <DrivePlayerCard
        v-if="item.type==='doc'"
        :id="item.key"
        @openTranscript="openTranscript"
        @deletePodcast="deletePodcast"
        :podcast="item"
        :podcastKey="item.key"
        :podIndex="i"
        :highlight="highlightFirst"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { getProjects } from '@/services/api/project'
import { deletePodcast } from '@/services/api/podcast'

import DrivePlayerCard from '@/components/Drive/DrivePlayerCard.vue'
import ProjectCard from '@/components/Drive/core/main/Projects/ProjectCard.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
import BrandIcon from '@/components/base/icons/Brand.vue'
import Play from '@/components/base/icons/Play.vue'
import Pause from '@/components/base/icons/Pause.vue'

export default {
  name: 'Latest',
  components: {
    ProjectCard,
    DrivePlayerCard,
    DeleteIcon,
    BrandIcon,
    Play,
    Pause
  },
  data: function() {
    return {
      projects: []
    }
  },
  props: {
    highlightFirst: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      files: 'app/computedList',
      user: 'app/user',
      uploadingList: 'app/uploadingList'
    }),
    list: function() {
      const projects = this.projects.map(el => ({ ...el, type: 'project' })).slice(0, 10)
      const docs = this.files.filter(el => el.updated && el.opened).slice(0, 10).map(el => ({ ...el, type: 'doc', uid: el.key }))
      return [...projects, ...docs].sort((current, next) => (dayjs(next.updated).isAfter(dayjs(current.updated)) ? 1 : -1))
    }

  },
  methods: {
    deletePodcast (key) {
      deletePodcast(this.user.uid, key)
    },
    openTranscript (key) {
      this.routeToEditor(key)
    },
    routeToEditor (podId) {
      let owner = this.files.find(el => el.key === podId).owner || this.user.uid
      this.$router.push({ 'name': 'HomeProse', 'params': { 'podId': podId, 'userId': owner } })
    },
    cancelUpload (key) {
      var vm = this
      var r = confirm('Are you sure you want to cancel ?')
      if (r) {
        // console.log('canceled')
        vm.uploadingList[key].task.cancel()
        store.commit('app/removeFromUploadingList', key)
      } else {
        console.log('You pressed Cancel!')
      }
    },
    shouldShowProject(proj) {
      if (proj.deleted) return false
      if (proj.hasOwnProperty('isShownOnDrive')) {
        return proj.isShownOnDrive
      } else {
        return true
      }
    }
  },
  created: function() {
    getProjects(
      {
        userId: this.user.uid
      }
    ).on('value', snap => {
      this.projects = Object
        .values(snap.val() || {})
        .filter(el => this.shouldShowProject(el))
        .sort((current, next) => next.updated - current.updated)
        .slice(0, 10)
    })
  },
  watch: {
    uploadingList: function(val) {
      console.log('uploadingList', val)
    }
  }
}
</script>

<style lang="scss">
  .no-last-border {
    border: none !important;
    >div {
      border: none !important;
    }
  }
</style>

<template>
  <div class="projects">
    <div v-if="Object.keys(projectsToShow).length === 0" class="flex flex-col items-center pt-s128">
      <BrandIcon class="text-red h-11 w-11" />
      <div class="mt-2 text-sm leading-6 text-primary text-center" style="max-width: 228px">
        Welcome to Spext 🙂
        <br />
        To begin, upload a file or start a project on the right.
      </div>
    </div>
    <ProjectCard
      v-for="(project, i) in projectsToShow"
      :key="project.uid"
      :project="project"
      v-on="$listeners"
      :class="{'no-last-border': i === projectsToShow.length-1}"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { getProjects } from '@/services/api/project'
import ProjectCard from './ProjectCard.vue'
import BrandIcon from '@/components/base/icons/Brand.vue'

export default {
  components: {
    ProjectCard,
    BrandIcon
  },
  props: {
    showUploadModal: {
      type: Function,
      default: () => {}
    }
  },
  data: function() {
    return {
      projects: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    projectsToShow: function() {
      return Object.values(this.projects || {})
        .filter(el => this.shouldShowProject(el))
        .sort((current, next) =>
          dayjs(next.updated).isAfter(dayjs(current.updated)) ? 1 : -1
        )
    }
  },
  methods: {
    shouldShowProject(proj) {
      if (proj.deleted) return false
      if (proj.hasOwnProperty('isShownOnDrive')) {
        return proj.isShownOnDrive
      } else {
        return true
      }
    }
  },
  created: function() {
    getProjects({
      userId: this.user.uid
    }).on('value', snap => {
      this.projects = snap.val()
    })
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3.5 px-2.5 mr-2.5 mt-0 relative group border-b border-primary-200 cont",class:{
    'opacity-70': _vm.podcast.doc_progress < 100,
    'bg-white rounded-lg project-ob transition-all mt-2.5': _vm.highlight,
  }},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center w-full relative"},[(_vm.podcast.code in _vm.errorCode && _vm.action !== 'delete')?_c('div',[_c('WarningIcon',{staticClass:"h-7 mr-3 text-red w-7"})],1):(_vm.podcast.doc_progress < 100)?_c('div',[_c('BrandIcon',{staticClass:"h-7 mr-3 text-red w-7 cursor-wait"})],1):_c('div',{on:{"click":_vm.openTranscript}},[_c('BrandIcon',{staticClass:"h-7 mr-3 text-red w-7 cursor-pointer"})],1),_vm._v(" "),_c('div',{staticClass:"w-full-custom relative"},[_c('div',{staticClass:"text-sm"},[(_vm.podcast.code in _vm.errorCode)?_c('div',{staticClass:"text-red title"},[_vm._v("\n            "+_vm._s(_vm.podcast.title)+"\n          ")]):(_vm.podcast.doc_progress < 100)?_c('div',{staticClass:"cursor-wait text-primary-700 title"},[_vm._v("\n            "+_vm._s(_vm.podcast.title)+"\n          ")]):_c('div',{staticClass:"cursor-pointer text-primary group-hover:text-red title",class:{
              titleTruncated: _vm.action === 'delete',
            },on:{"click":_vm.openTranscript}},[_vm._v("\n            "+_vm._s(_vm.podcast.title)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"\n            mt-0.5\n            text-xs text-primary-500\n            group-hover:text-primary-700\n            title\n          "},[(_vm.podcast.code in _vm.errorCode)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.errorCode[_vm.podcast.code])}}):(_vm.podcast.doc_progress < 100)?_c('div',[_vm._v("\n            "+_vm._s(_vm.fixed(_vm.podcast.doc_progress))),_c('saving')],1):_c('div',[_vm._v(_vm._s(_vm.podcast.status))])])])]),_vm._v(" "),(_vm.action === 'delete')?_c('div',{staticClass:"flex rightPropmpt items-center"},[_c('span',{staticClass:"text-sm"},[_vm._v("Are you sure you want to delete this file?")]),_vm._v(" "),_c('Button',{staticClass:"ml-2.5",attrs:{"size":"small","variant":"secondary"},on:{"click":function($event){_vm.action = 'play'}}},[_vm._v("\n        No\n      ")]),_vm._v(" "),_c('Button',{staticClass:"ml-2.5",attrs:{"size":"small"},on:{"click":_vm.deletePodcast}},[_vm._v("\n        Yes\n      ")])],1):_c('div',{staticClass:"flex rightIcons items-center opacity-20 group-hover:opacity-100",class:{
        'opacity-100': _vm.showExportedFiles,
      }},[(_vm.podcast.doc_progress < 100)?_c('Button',{staticClass:"hidden group-hover:block",class:[_vm.podcast.code in _vm.errorCode ? 'hidden' : ''],attrs:{"size":"small","variant":"secondary"},on:{"click":function($event){return _vm.notPresent('No exported files found')}}},[_vm._v("\n        Exports\n      ")]):_c('Button',{staticClass:"align-center justify-between group-hover:flex export-toggle",class:{
          hidden: !_vm.showExportedFiles,
          'border-primary-300 bg-primary-300 flex text-primary':
            _vm.showExportedFiles,
        },attrs:{"size":"small","variant":"secondary"},on:{"click":_vm.toggleExportedFiles}},[_vm._v("\n        Exports\n        "),_c('ChevronDown',{staticClass:"ml-1 export-chevron",class:{
            'rotate-vertical': _vm.showExportedFiles,
          }})],1),_vm._v(" "),(_vm.podcast.doc_progress === 100)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Copy'),expression:"'Copy'",modifiers:{"top":true}}],staticClass:"\n          cursor-pointer\n          ml-4\n          text-primary-500\n          actionIcon\n          group-hover:text-primary group-hover:block\n        ",class:{
          hidden: !_vm.showExportedFiles,
          'text-primary block': _vm.showExportedFiles,
        },on:{"click":function($event){return _vm.makeCopy(_vm.podcastKey)}}},[_c('CopyIcon')],1):(_vm.podcast.doc_progress < 100)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Copy'),expression:"'Copy'",modifiers:{"top":true}}],staticClass:"ml-4 hidden group-hover:block",class:[
          _vm.podcast.code in _vm.errorCode ? 'hidden' : '',
          'text-primary-400' ],on:{"click":function($event){return _vm.notPresent('Processing Audio ...')}}},[_c('CopyIcon')],1):_vm._e(),_vm._v(" "),(_vm.podcast.doc_progress < 100 && _vm.podcast.doc_progress > 0)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Delete'),expression:"'Delete'",modifiers:{"top":true}}],staticClass:"ml-4 text-primary-400",on:{"click":function($event){return _vm.notPresent("Can't delete while processing the audio.")}}},[_c('DeleteIcon')],1):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Delete'),expression:"'Delete'",modifiers:{"top":true}}],staticClass:"\n          cursor-pointer\n          ml-4\n          text-primary-500\n          deleteIcon\n          group-hover:text-primary\n        ",class:{
          'text-primary': _vm.showExportedFiles,
        },on:{"click":_vm.stopAndDelete}},[_c('DeleteIcon')],1)],1)]),_vm._v(" "),(_vm.showExportedFiles && Object.keys(_vm.podcast.exportedList).length)?_c('div',{staticClass:"mt-4.5 grid gap-4 grid-cols-3 pl-9 pr-5"},_vm._l((_vm.podcast.exportedList),function(file,key){return _c('div',{key:key,staticClass:"\n        border\n        rounded\n        flex\n        border-primary-300\n        mb-2.5\n        py-3.5\n        px-4\n        col-span-1\n        items-center\n        justify-between\n      ",class:{
        'cursor-pointer': file.status === 'done',
        'hover:border-primary': file.status === 'done',
      },on:{"click":function($event){return _vm.downloadURL(file, key)}}},[_c('div',{staticClass:"text-sm overflow-ellipsis whitespace-nowrap overflow-hidden",attrs:{"title":_vm.fileTypeMapping[key]}},[_vm._v("\n        "+_vm._s(_vm.fileTypeMapping[key])+"\n      ")]),_vm._v(" "),(file.status === 'start' || file.status === 'process')?_c('div',{staticClass:"text-green"},[_c('CircularLoader')],1):_c('div',{class:[
          'text-primary relative flex flex-col items-center ml-1',
          file.status === 'done' ? 'cursor-pointer' : '' ]},[_c('MediaFileIcon'),_vm._v(" "),_c('div',{staticClass:"font-bold text-white top-3 absolute",staticStyle:{"font-size":"7px","line-height":"8px"}},[_vm._v("\n          "+_vm._s(_vm.fileExtMapping[key])+"\n        ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(file.status === 'done'),expression:"file.status === 'done'"}],staticClass:"\n            bg-green\n            border border-white\n            rounded-full\n            flex\n            h-4\n            text-white\n            top-4.5\n            -left-1\n            w-4\n            absolute\n            items-center\n            justify-center\n          "},[_c('DownloadIcon')],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(file.status === 'error'),expression:"file.status === 'error'"}],staticClass:"\n            bg-red\n            border border-white\n            rounded-full\n            flex\n            h-4\n            text-white\n            top-4.5\n            -left-1\n            w-4\n            absolute\n            items-center\n            justify-center\n          "},[_c('CloseIcon',{attrs:{"classname":"h-2.5 w-2.5"}})],1)],1)])}),0):_vm._e(),_vm._v(" "),(_vm.showExportedFiles && !Object.keys(_vm.podcast.exportedList).length)?_c('div',{staticClass:"flex mt-4.5 text-primary-500 items-center justify-center"},[_c('FileAlertIcon'),_vm._v(" "),_c('span',{staticClass:"text-sm ml-4"},[_vm._v("No exported file found")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
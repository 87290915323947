<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79093 13.332C11.7365 13.332 14.1243 10.9442 14.1243 7.9987C14.1243 5.05318 11.7365 2.66536 8.79093 2.66536C5.84542 2.66536 3.4576 5.05318 3.4576 7.9987C3.4576 10.9442 5.84542 13.332 8.79093 13.332ZM8.79093 14.6654C12.4728 14.6654 15.4576 11.6806 15.4576 7.9987C15.4576 4.3168 12.4728 1.33203 8.79093 1.33203C5.10904 1.33203 2.12427 4.3168 2.12427 7.9987C2.12427 11.6806 5.10904 14.6654 8.79093 14.6654Z"/>
    <path d="M9.26242 4.19526C9.1374 4.07024 8.96783 4 8.79102 4C8.61421 4 8.44464 4.07024 8.31961 4.19526L6.98628 5.5286C6.72593 5.78894 6.72593 6.21105 6.98628 6.4714C7.24663 6.73175 7.66874 6.73175 7.92909 6.4714L8.12435 6.27614V11.3333C8.12435 11.7015 8.42283 12 8.79102 12C9.15921 12 9.45768 11.7015 9.45768 11.3333V6.27614L9.65294 6.4714C9.91329 6.73175 10.3354 6.73175 10.5958 6.47141C10.8561 6.21106 10.8561 5.78895 10.5958 5.5286L9.26242 4.19526Z"/>
  </svg>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="7"
    viewBox="0 0 6 7"
    :class="classname"
  >
    <path d="M4 1.00098C4 0.448692 3.55228 0.000976562 3 0.000976562C2.44772 0.000976562 2 0.448692 2 1.00098V3.58676L1.70711 3.29387C1.31658 2.90335 0.683417 2.90335 0.292893 3.29387C-0.0976311 3.68439 -0.0976311 4.31756 0.292893 4.70808L2.29289 6.70808C2.68342 7.09861 3.31658 7.09861 3.70711 6.70808L5.70711 4.70808C6.09763 4.31756 6.09763 3.68439 5.70711 3.29387C5.31658 2.90335 4.68342 2.90335 4.29289 3.29387L4 3.58676V1.00098Z" />
  </svg>
</template>

<script>
export default {
  name: 'Download',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>

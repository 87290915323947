<template>
  <div
    class="py-3.5 px-2.5 mr-2.5 mt-0 relative group border-b border-primary-200 cont"
    :class="{
      'opacity-70': podcast.doc_progress < 100,
      'bg-white rounded-lg project-ob transition-all mt-2.5': highlight,
    }"
  >
    <div class="flex items-center justify-between">
      <!-- 1. left -->
      <div class="flex items-center w-full relative">
        <div v-if="podcast.code in errorCode && action !== 'delete'">
          <WarningIcon class="h-7 mr-3 text-red w-7" />
        </div>
        <div v-else-if="podcast.doc_progress < 100">
          <BrandIcon class="h-7 mr-3 text-red w-7 cursor-wait" />
        </div>
        <div v-else @click="openTranscript">
          <BrandIcon class="h-7 mr-3 text-red w-7 cursor-pointer" />
        </div>

        <div class="w-full-custom relative">
          <div class="text-sm">
            <div v-if="podcast.code in errorCode" class="text-red title">
              {{ podcast.title }}
            </div>
            <div
              v-else-if="podcast.doc_progress < 100"
              class="cursor-wait text-primary-700 title"
            >
              {{ podcast.title }}
            </div>
            <div
              v-else
              @click="openTranscript"
              class="cursor-pointer text-primary group-hover:text-red title"
              :class="{
                titleTruncated: action === 'delete',
              }"
            >
              {{ podcast.title }}
            </div>
          </div>

          <div
            class="
              mt-0.5
              text-xs text-primary-500
              group-hover:text-primary-700
              title
            "
          >
            <!-- error sub title -->
            <div
              v-if="podcast.code in errorCode"
              v-html="errorCode[podcast.code]"
            />
            <!-- progress & time -->
            <div v-else-if="podcast.doc_progress < 100">
              {{ fixed(podcast.doc_progress) }}<saving />
            </div>
            <!-- updated time -->
            <div v-else>{{ podcast.status }}</div>
          </div>
        </div>
      </div>

      <!-- 2. right section -->
      <div v-if="action === 'delete'" class="flex rightPropmpt items-center">
        <span class="text-sm">Are you sure you want to delete this file?</span>
        <Button
          class="ml-2.5"
          size="small"
          variant="secondary"
          @click="action = 'play'"
        >
          No
        </Button>
        <Button class="ml-2.5" size="small" @click="deletePodcast">
          Yes
        </Button>
      </div>
      <div
        v-else
        class="flex rightIcons items-center opacity-20 group-hover:opacity-100"
        :class="{
          'opacity-100': showExportedFiles,
        }"
      >
        <!-- exported files -->
        <Button
          v-if="podcast.doc_progress < 100"
          size="small"
          variant="secondary"
          class="hidden group-hover:block"
          :class="[podcast.code in errorCode ? 'hidden' : '']"
          @click="notPresent('No exported files found')"
        >
          Exports
        </Button>
        <Button
          v-else
          size="small"
          variant="secondary"
          class="align-center justify-between group-hover:flex export-toggle"
          :class="{
            hidden: !showExportedFiles,
            'border-primary-300 bg-primary-300 flex text-primary':
              showExportedFiles,
          }"
          @click="toggleExportedFiles"
        >
          Exports
          <ChevronDown
            class="ml-1 export-chevron"
            :class="{
              'rotate-vertical': showExportedFiles,
            }"
          />
        </Button>

        <!-- Make a copy -->
        <div
          v-if="podcast.doc_progress === 100"
          class="
            cursor-pointer
            ml-4
            text-primary-500
            actionIcon
            group-hover:text-primary group-hover:block
          "
          :class="{
            hidden: !showExportedFiles,
            'text-primary block': showExportedFiles,
          }"
          @click="makeCopy(podcastKey)"
          v-tooltip.top="'Copy'"
        >
          <CopyIcon />
        </div>
        <div
          v-else-if="podcast.doc_progress < 100"
          @click="notPresent('Processing Audio ...')"
          class="ml-4 hidden group-hover:block"
          :class="[
            podcast.code in errorCode ? 'hidden' : '',
            'text-primary-400',
          ]"
          v-tooltip.top="'Copy'"
        >
          <CopyIcon />
        </div>

        <!-- delete button -->
        <!-- processing -->
        <div
          v-if="podcast.doc_progress < 100 && podcast.doc_progress > 0"
          @click="notPresent(`Can't delete while processing the audio.`)"
          class="ml-4 text-primary-400"
          v-tooltip.top="'Delete'"
        >
          <DeleteIcon />
        </div>
        <div
          v-else
          class="
            cursor-pointer
            ml-4
            text-primary-500
            deleteIcon
            group-hover:text-primary
          "
          :class="{
            'text-primary': showExportedFiles,
          }"
          @click="stopAndDelete"
          v-tooltip.top="'Delete'"
        >
          <DeleteIcon />
        </div>
      </div>
    </div>

    <div
      v-if="showExportedFiles && Object.keys(podcast.exportedList).length"
      class="mt-4.5 grid gap-4 grid-cols-3 pl-9 pr-5"
    >
      <div
        :key="key"
        v-for="(file, key) in podcast.exportedList"
        class="
          border
          rounded
          flex
          border-primary-300
          mb-2.5
          py-3.5
          px-4
          col-span-1
          items-center
          justify-between
        "
        :class="{
          'cursor-pointer': file.status === 'done',
          'hover:border-primary': file.status === 'done',
        }"
        @click="downloadURL(file, key)"
      >
        <div
          class="text-sm overflow-ellipsis whitespace-nowrap overflow-hidden"
          :title="fileTypeMapping[key]"
        >
          {{ fileTypeMapping[key] }}
        </div>
        <div
          v-if="file.status === 'start' || file.status === 'process'"
          class="text-green"
        >
          <CircularLoader />
        </div>
        <div
          v-else
          :class="[
            'text-primary relative flex flex-col items-center ml-1',
            file.status === 'done' ? 'cursor-pointer' : '',
          ]"
        >
          <MediaFileIcon />

          <div
            class="font-bold text-white top-3 absolute"
            style="font-size: 7px; line-height: 8px"
          >
            {{ fileExtMapping[key] }}
          </div>

          <div
            v-show="file.status === 'done'"
            class="
              bg-green
              border border-white
              rounded-full
              flex
              h-4
              text-white
              top-4.5
              -left-1
              w-4
              absolute
              items-center
              justify-center
            "
          >
            <DownloadIcon />
          </div>

          <div
            v-show="file.status === 'error'"
            class="
              bg-red
              border border-white
              rounded-full
              flex
              h-4
              text-white
              top-4.5
              -left-1
              w-4
              absolute
              items-center
              justify-center
            "
          >
            <CloseIcon classname="h-2.5 w-2.5" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex mt-4.5 text-primary-500 items-center justify-center"
      v-if="showExportedFiles && !Object.keys(podcast.exportedList).length"
    >
      <FileAlertIcon />
      <span class="text-sm ml-4">No exported file found</span>
    </div>
  </div>
</template>
<script>
import * as firebase from 'firebase/app'
import 'firebase/storage'
import { Howl } from 'howler'
import CONSTANTS from '@/constants/CONSTANTS'
import axios from 'axios'
import store from '@/services/store'

import Button from '@/components/base/buttons/Button.vue'
import WarningIcon from '@/components/base/icons/Warning.vue'
import BrandIcon from '@/components/base/icons/Brand.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import MediaFileIcon from '@/components/base/icons/MediaFile.vue'
import CopyIcon from '@/components/base/icons/Copy.vue'
import DownloadIcon from '@/components/base/icons/Download.vue'
import CloseIcon from '@/components/base/icons/Close.vue'
import FileAlertIcon from '@/components/base/icons/FileAlert.vue'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'

require('@fortawesome/fontawesome-pro/css/all.css')
const uuidv1 = require('uuid/v1')
const fileTypeMapping = {
  wav: 'WAV',
  flac: 'FLAC',
  mp3: 'MP3',
  ogg: 'OGG',
  post_production_wav: 'Post Produced WAV',
  post_production_flac: 'Post Produced FLAC',
  post_production_mp3: 'Post Produced MP3',
  post_production_ogg: 'Post Produced OGG',
  pdf: 'PDF',
  srt: 'SRT',
  vtt: 'VTT',
  doc: 'DOCX',
  redact_audio: 'Redacted Audio',
  redact_video: 'Redacted Video',
  post_production: 'Post Produced File',
  sesx: 'Adobe Audition Session',
  mp4: 'MP4'
}

const fileExtMapping = {
  wav: 'WAV',
  flac: 'FLAC',
  mp3: 'MP3',
  ogg: 'OGG',
  post_production_wav: 'WAV',
  post_production_flac: 'FLAC',
  post_production_mp3: 'MP3',
  post_production_ogg: 'OGG',
  pdf: 'PDF',
  srt: 'SRT',
  vtt: 'VTT',
  doc: 'DOCX',
  sesx: 'SESX',
  mp4: 'MP4'
}
export default {
  name: 'DrivePlayerCard',
  props: ['podcast', 'podcastKey', 'podIndex', 'highlight'],
  components: {
    Button,
    DeleteIcon,
    MediaFileIcon,
    WarningIcon,
    BrandIcon,
    CircularLoader,
    CopyIcon,
    DownloadIcon,
    CloseIcon,
    FileAlertIcon,
    ChevronDown,
    saving: () => import('../mini-components/Saving')
  },
  data() {
    return {
      player: '',
      status: 'Play',
      action: 'play',
      errorCode: CONSTANTS.ERROR_CODES,
      showExportedFiles: false,
      fileTypeMapping,
      fileExtMapping
    }
  },
  beforeDestroy() {
    // console.log('destroying drive player card')
    var vm = this
    if (vm.player !== '') {
      vm.player.unload()
      vm.player = ''
    }
  },
  mounted() {
    // console.log('mounted drivePlayer')
  },
  computed: {
    user() {
      return store.state.app.user
    }
  },
  methods: {
    fixed(num) {
      // console.log(typeof (num))
      num = parseFloat(num)
      if (num === 1) {
        return 'Queued'
      }
      return 'Transcribing ' + num.toFixed(2) + '%'
    },
    uppercase(str) {
      return str.toUpperCase()
    },
    openTranscript() {
      this.$emit('openTranscript', this.podcastKey, this.podIndex)
    },
    openClipper() {
      this.$emit('openClipper', this.podcastKey, this.podIndex)
    },
    makeCopy(key) {
      let nKey = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      let uid = this.user.uid
      let params = {
        media_id: key,
        new_id: nKey,
        userid: uid,
        owner: this.podcast.owner
      }
      this.$parent.$parent.$parent.showToast('Copying file')
      axios
        .get(CONSTANTS.MAKE_COPY, {
          params: params
        })
        .catch(function (err) {
          console.log('err', err)
        })
    },
    notPresent(msg) {
      var vm = this
      vm.$parent.$parent.$parent.showToast(msg, 'warning')
    },
    deletePodcast() {
      var vm = this
      vm.$emit('deletePodcast', vm.podcastKey)
    },
    toggleExportedFiles() {
      var vm = this
      vm.showExportedFiles = !vm.showExportedFiles
      // $('#exportedFiles').transition('slide down')
    },
    downloadURL(data, type) {
      if (data.status === 'done') {
        var url = data.url
        var vm = this
        vm.$parent.downloadInProgress += 1
        if (url.indexOf('http') >= 0) {
          var a = document.createElement('a')
          a.href = url
          a.target = '_blank'
          a.download = 'export.' + type
          a.click()
          vm.$parent.downloadInProgress -= 1
        } else {
          var storage = firebase.storage()
          var httpsReference = storage.ref(url)
          httpsReference
            .getDownloadURL()
            .then(function (url) {
              var a = document.createElement('a')
              a.href = url
              a.download = vm.podcast.title + '.' + type
              a.target = '_blank'
              a.click()
              vm.$parent.downloadInProgress -= 1
            })
            .catch(function (err) {
              console.log(err)
              vm.$parent.downloadInProgress -= 1
            })
        }
      } else if (data.status === 'error') {
        Intercom('showNewMessage', 'I am having trouble in exporting.')
      }
    },
    getMyDownloadURL() {
      var vm = this
      var url = vm.podcast.audio_url
      if (url.indexOf('http') >= 0) {
        vm.initPlayer(url)
      } else {
        var storage = firebase.storage()
        var httpsReference = storage.ref(url)
        httpsReference.getDownloadURL().then(function (newUrl) {
          console.log('url is', newUrl)
          vm.initPlayer(newUrl)
        })
      }
    },
    initPlayer(url) {
      var vm = this
      vm.player = new Howl({
        src: [url],
        preload: false,
        onplay: function () {
          console.log('playing')
          vm.status = 'Pause'
          vm.action = 'play'
        },
        onload: function () {
          console.log('loaded')
          vm.playPauseAudio()
          vm.action = 'play'
        },
        onend: function () {
          console.log('ended playing')
          vm.status = 'Play'
          vm.action = 'play'
        },
        onpause: function () {
          console.log('paused')
          vm.status = 'Play'
          vm.action = 'play'
        },
        onstop: function () {
          console.log('stopped')
          vm.status = 'Play'
          // Dont add vm.action = 'play' here otherwise delete doesn't work
        },
        onplayerror: function () {
          console.log('play error')
          vm.status = 'Play'
          vm.action = 'play'
        },
        onloaderror: function () {
          console.log('load error')
          vm.status = 'Play'
          vm.action = 'play'
        }
      })
      vm.loadAudio()
    },
    playPauseAudio() {
      console.log('playPauseAudio')
      var vm = this
      if (vm.podcast.audio_url === undefined) {
        return
      }
      if (vm.player === '') {
        vm.action = 'loading'
        vm.getMyDownloadURL()
      } else {
        vm.loadAudio()
      }
    },
    loadAudio() {
      var vm = this
      if (vm.player.state() === 'unloaded') {
        vm.player.load()
        vm.action = 'loading'
      } else if (vm.player.playing()) {
        vm.player.pause()
        vm.status = 'Play'
      } else {
        vm.player.play()
        vm.status = 'Pause'
      }
    },
    stopAudio() {
      var vm = this
      if (vm.player !== '') {
        vm.player.stop()
      }
    },
    stopAndDelete() {
      var vm = this
      vm.showExportedFiles = false
      vm.stopAudio()
      vm.action = 'delete'
    }
  }
}
</script>

<style lang="scss" scoped>
.rightPropmpt {
  position: absolute;
  z-index: 2;
  right: 24px;
}

.rightIcons {
  position: absolute;
  z-index: 2;
  right: 24px;
}

.actionIcon:hover {
  color: #0075ff !important;
}

.deleteIcon:hover {
  color: #e2462c !important;
}

.cont {
  &:hover {
    .title {
      font-weight: 600;
    }
  }
}

.mw-100 {
  max-width: 100px;
}

.export-toggle {
  .export-chevron {
    transition: all 0.2s ease;
  }
  .rotate-vertical {
    transform: scaleY(-1);
  }
  &:hover {
    .export-chevron {
      transform: translateY(2px);
    }

    .rotate-vertical.export-chevron {
      transform: scaleY(-1) translateY(2px);
    }
  }
}

.title {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 240px);
}

.title.titleTruncated {
  max-width: calc(100% - 420px);
}

.w-full-custom {
  width: calc(100% - 40px);
}

.project-ob {
  z-index: 51;
}
</style>

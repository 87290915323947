<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path d="M8.72154 2.54638C6.73333 1.15325 4 2.57555 4 5.00327V18.996C4 21.4238 6.73336 22.8461 8.72156 21.4529L18.7297 14.4401C20.4229 13.2536 20.4229 10.7455 18.7297 9.55906L8.72154 2.54638Z" />
  </svg>
</template>

<script>
export default {
  name: 'Play',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>

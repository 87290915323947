import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
// import CONSTANTS from '../../constants/CONSTANTS'
// firebase.initializeApp(CONSTANTS.CONFIG)

// export function getPodcastMeta (userId, podId) {
//   let getData = getDataFromPath(userId, podId)
//   let meta = getData('podcast-info', '')
//   return meta.then(function (meta) {
//     console.timeEnd('getMeta')
//     return meta
//   })
// }

export function getPodcastMeta (userId, podId) {
  const url = `${userId}/podcast-info/${podId}`
  return firebase.database().ref(url)
}

export function getPodcastExports (userId, podId, version) {
  const url = `${userId}/podcast-info/${podId}/exported_files/${version}`
  return firebase.database().ref(url)
}

export function saveExportedFileMeta (userId, podcastId, version, fileType, fileMeta, successCallback, failureCallback) {
  let path = `${userId}/podcast-info/${podcastId}/exported_files/${version}/${fileType}`
  firebase.database().ref(path).set(fileMeta).then(function () {
    successCallback()
  }).catch(function (err) {
    console.error(err)
    failureCallback()
  })
}

// // Closure
// // can get data from any path
// function getDataFromPath (userId, podId) {
//   return (path1, path2) => {
//     const url = `${userId}/${path1}/${podId}/${path2}`
//     return firebase.database()
//       .ref(url)
//       .once('value').then(function (value) {
//         if (value.val() !== null) {
//           console.log('Getting ', url)
//           return value.val()
//         } else {
//           throw new Error('Not present')
//         }
//       })
//   }
// }

export function deletePodcastOld (uid, podId) {
  console.log('delete podcast', podId)
  if (uid && podId) {
    console.log('deleting', uid, podId)
    const oldRef = firebase.database().ref(uid + '/podcast-info/' + podId)
    const newRef = firebase.database().ref(uid + '/removed-podcast-info/' + podId)
    oldRef.once('value', function (snap) {
      newRef.set(snap.val(), function (error) {
        if (!error) {
          oldRef.remove()
        } else {
          console.error(error)
        }
      })
    })
  }
}

export function deletePodcast (userId, podId, deleted = true) {
  return firebase.database().ref(userId + '/podcast-info/' + podId + '/deleted').set(deleted)
}

export function updatePodcast (userId, podId, update) {
  return firebase.database().ref(userId + '/podcast-info/' + podId).update(update)
}

export function stringifyPara (docc) {
  docc.content = docc.content.map(function (para) { return JSON.stringify(para) })
  return docc
}

<template>
  <div>
    <div v-if="Object.keys(files).length === 0" class="flex flex-col items-center pt-s208">
      <img src="../../../../../assets/images/empty-music.svg" class="w-s40 mb-s12" />
      <div class="text-sm leading-6 text-primary-500 text-center">
        You can upload your own music files here.<br/>
        Start by clicking below!
      </div>

      <Button
        variant="primary"
        class="mt-7.5"
        @click="$emit('uploadSound')"
      >
        Upload Music
      </Button>
    </div>

    <div
      v-if="Object.keys(files).length"
      class="mt-1 pl-4 pr-2 py-2 bg-primary-100 flex justify-between items-center"
    >
      <div class="font-h text-primary font-semibold">
        Available Files
      </div>

      <Button
        variant="secondary"
        class="bg-white"
        @click="$emit('uploadSound')"
      >
        Upload Music
      </Button>
    </div>
    <div
      v-for="(fileList, index) in files"
      class="py-3.5 px-2.5 mr-2.5 group border-b border-primary-200 flex items-center justify-between audio-list"
      :key="index"
    >
      <div class="flex items-center h-7.5">
        <div v-if="selectedFile===index">
          <div v-if="!status || status==='pause'" @click="setPlay(index)" class="cursor-pointer text-primary hover:text-red group-hover:text-red">
            <MusicPlay class="h-6 w-6"/>
          </div>
          <div v-else-if="status==='seeking'" @click="setPause(index)" class="text-primary">
            <CircularLoader class="h-6 w-6"/>
          </div>
          <div v-else-if="status==='playing'" @click="setPause(index)" class="cursor-pointer text-primary hover:text-red group-hover:text-red">
            <MusicPause class="h-6 w-6"/>
          </div>
        </div>
        <div v-else @click="setPlay(index)" class="cursor-pointer text-primary group-hover:text-red hover:text-red">
          <MusicPlay class="h-6 w-6"/>
        </div>

        <div class="text-sm text-primary ml-3 group-hover:text-red cursor-pointer">{{fileList.title}}</div>
      </div>

      <div v-if="deleteList[index]" class="flex items-center">
        <span class="text-xs">Delete this File?</span>
        <Button
          class="ml-2.5"
          size="small"
          variant="secondary"
          @click="removeFromDeleteList(index)"
        >
          No
        </Button>
        <Button
          class="ml-2.5"
          size="small"
          @click="deleteFile(index)"
        >
          Yes
        </Button>
      </div>
      <div
        v-else
        class="cursor-pointer text-primary-500 hover:text-red"
        @click="addToDeleteList(index)"
        v-tooltip.top="'Delete music'"
      >
        <DeleteIcon />
      </div>
    </div>

    <audio
      @playing="playing"
      @pause="pause"
      @ended="ended"
      @seeking="seeking"
      @waiting="waiting"
      ref="audio"
    />
  </div>
</template>

<script>
import store from '@/services/store'
import { getURLfromPath } from '@/view/voiceEditor/vue/components/AddFiles/utility'
import { deleteUploadedMedia } from '@/services/api/media'
import CONSTANTS from '@/constants/CONSTANTS'

import Button from '@/components/base/buttons/Button.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import MusicPlay from '@/components/base/icons/MusicPlay.vue'
import MusicPause from '@/components/base/icons/MusicPause.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'

export default {
  name: 'Sound',
  data () {
    return {
      selectedFile: '',
      status: '',
      deleteList: {}
    }
  },
  components: {
    Button,
    CircularLoader,
    MusicPlay,
    MusicPause,
    DeleteIcon
  },
  computed: {
    showCreate () {
      if (this.userInfo && this.userInfo.billing && Object.keys(this.userInfo.billing).length > 2 && ((this.userInfo.billing.subscribed && CONSTANTS.VOICE_PLANS.indexOf(this.userInfo.billing.subscribed) >= 0) || (this.userInfo.billing.trial))) {
        return true
      }
      return false
    },
    userInfo () {
      return store.state.app.userInfo
    },
    uploadedMedia () {
      return store.state.app.uploadedMedia
    },
    user () {
      return store.state.app.user
    },
    files () {
      let list = {}
      for (let key in this.uploadedMedia) {
        if (!this.uploadedMedia[key].deleted) {
          list[key] = this.uploadedMedia[key]
        }
      }
      return list
    }
  },
  methods: {
    deleteFile (key) {
      // console.log(this.user.uid)
      deleteUploadedMedia(this.user.uid, key).then(function () {
        console.log('deleted sound file')
        store.commit('app/removeUploadedMedia', { key: key })
      })
    },
    removeFromDeleteList (key) {
      this.$set(this.deleteList, key, false)
    },
    addToDeleteList (key) {
      this.$set(this.deleteList, key, true)
      this.$refs.audio.pause()
    },
    displayOrHideIcons (i, showIcon = false) {
      this.fileLists[i].showIcon = showIcon
    },
    setPlay (index) {
      console.log('index is', index)
      this.$refs.audio.pause()
      this.status = 'pause'
      this.selectedFile = index
      let vm = this
      let turl = vm.files[index].url
      if (turl) {
        vm.status = 'seeking'
        getURLfromPath(turl)
          .then(function (url) {
            console.log('url is', url)
            vm.$refs.audio.src = url
            vm.$refs.audio.play()
          })
      }
    },
    setPause (index) {
      this.$refs.audio.pause()
    },
    playing () {
      console.log('playing')
      this.status = 'playing'
    },
    pause () {
      console.log('paused')
      this.status = 'pause'
    },
    ended () {
      console.log('ended')
      this.status = ''
    },
    seeking () {
      console.log('seeking')
      this.status = 'seeking'
    },
    waiting () {
      console.log('wating')
      this.status = 'seeking'
    }
  }
}
</script>

<style lang="scss" scoped>
  .audio-list:last-child {
    border: none;
  }

</style>

import { Project, ProjectUpdate } from '@/types/clipperTypes'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { v1 as uuidv1 } from 'uuid'

export const createProject = (owner: string, projectInfo: Partial<Project>) => new Promise<string>((resolve, reject) => {
  console.log('being calledasdcasdckjnasdkjcnaskdjc')
  const newProjectId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
  firebase.database().ref(`${owner}/projects/${newProjectId}`)
    .set({
      ...projectInfo,
      owner,
      uid: newProjectId,
      created: Date.now(),
      updated: Date.now(),
      deleted: false
    })
    .then(() => {
      console.log('Created new project: ', newProjectId)
      resolve(newProjectId)
    })
    .catch(err => {
      console.error(err)
      reject(err)
    })
})

export const getProjects = ({ userId }: Pick<ProjectUpdate, 'userId'>) =>
  firebase.database().ref(`${userId}/projects`)

export const getProjectInfo = ({ userId, projectId }: Pick<ProjectUpdate, 'projectId' | 'userId'>) =>
  firebase.database().ref(`${userId}/projects/${projectId}`)

export const updateProject = ({ userId, projectId, update }: ProjectUpdate) => new Promise((resolve, reject) => {
  firebase.database().ref(`${userId}/projects/${projectId}`)
    .update({ ...update, updated: Date.now() })
    .then(resolve)
    .catch(reject)
})

<template>
  <button type="type" @click="emitClick()" :class="computedClass" :disabled="variant === 'disabled'">
    <slot/>
  </button>
</template>

<script>
import { iconbuttonTheme as theme } from '../theme'

export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    onClick: {
      type: Function,
      default: () => null
    },
    size: {
      type: String,
      default: 'normal'
    },
    variant: {
      type: String,
      default: 'normal'
    },
    classname: {
      type: String,
      default: ''
    }
  },
  methods: {
    emitClick: function() {
      this.onClick()
      this.$emit('click')
    }
  },
  computed: {
    computedClass: function() {
      return [theme.base, theme.sizes[this.size], theme.variants[this.variant], 'iconbutton', this.classname]
    }
  }
}
</script>

<style>

</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="27"
    viewBox="0 0 24 27"
    :class="classname"
  >
    <path d="M22.0399 10.0106H21.4086V6.96454C21.4086 6.94554 21.4056 6.9264 21.4031 6.90717C21.4021 6.78621 21.3636 6.66741 21.2808 6.57328L16.2118 0.782769C16.2103 0.781289 16.2088 0.780764 16.2077 0.779141C16.1775 0.745394 16.1423 0.717328 16.105 0.693032C16.094 0.685633 16.0829 0.679428 16.0714 0.673032C16.0391 0.655419 16.0049 0.640813 15.9696 0.630264C15.9601 0.627591 15.9515 0.623677 15.9419 0.621195C15.9037 0.612125 15.8639 0.606445 15.8236 0.606445H3.36581C2.79693 0.606445 2.33479 1.06912 2.33479 1.63751V10.0104H1.70362C0.889833 10.0104 0.22998 10.67 0.22998 11.484V19.1472C0.22998 19.9607 0.889833 20.6207 1.70362 20.6207H2.33483V25.8664C2.33483 26.4348 2.79698 26.8975 3.36585 26.8975H20.3777C20.946 26.8975 21.4087 26.4348 21.4087 25.8664V20.6207H22.04C22.8535 20.6207 23.5135 19.9607 23.5135 19.1472V11.4843C23.5135 10.6702 22.8535 10.0106 22.0399 10.0106ZM3.36581 1.6377H15.3081V6.91275C15.3081 7.19757 15.5392 7.42826 15.8236 7.42826H20.3776V10.011H3.36581V1.6377ZM20.3777 25.5873H3.36581V20.6207H20.3776L20.3777 25.5873Z" />
  </svg>
</template>

<script>
export default {
  name: 'CircularLoader',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
